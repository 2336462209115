import Reporting from './pages/Reporting';
import Login from './pages/Login';
import './App.css';
import RequireAuth from './components/RequireAuth';
import {Routes, Route} from 'react-router-dom';
import SignUp from './pages/SignUp';
import User from './pages/User';
import Users from './pages/Users';
import VariableColumns from './pages/VariableColumns';
import Layout from './components/Layout';
import Unauthorized from './pages/Unauthorized';

function App() {
  return (
    <Routes>
      {/* Public Routes */}
      <Route path="login" element={<Login/>} />
      <Route path="signup" element={<SignUp/>} />
      {/* Private Routes */}
      <Route element={<RequireAuth allowedRoles={[3943, 1387]} />}>
        <Route path="/" element={<Layout />}>
          <Route path="/" element={<Reporting/>} />
          <Route path="unauthorized" element={<Unauthorized/>} />
        </Route>
      </Route>
      {/* Admin Routes */}
      <Route element={<RequireAuth allowedRoles={[1387]} />}>
        <Route path="/" element={<Layout />}>
          <Route path="users" element={<Users/>} />
          <Route path="user/:userEmail" element={<User/>} />
          <Route path="variables" element={<VariableColumns/>} />
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
