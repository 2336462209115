import { Typography, Container } from '@mui/material';
import { Box } from '@mui/system';
import * as React from 'react';

function Unauthorized() {

  return (
    <Container component="main" maxWidth="xs">
      <Box>
        <Typography component="h1" variant="h1" style={{marginBottom: 30,marginTop: "150px", textAlign: "center", fontWeight: "bold"}}>
          401
        </Typography>
        <Typography component="p" variant="p" style={{marginBottom: 30, textAlign: "center"}}>
          You are unauthorized to view this page.
        </Typography>
      </Box>
    </Container>
  );
}

export default Unauthorized;
