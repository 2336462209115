import { Container, Grid, Alert, TextField, Button } from '@mui/material';
import { Box } from '@mui/system';
import { Link } from 'react-router-dom';
import * as React from 'react';
import {useState} from 'react';
import axios from '../api/axios';

// Assets
const logo = require('../assets/logo.png');

function SignUp() {

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [pwd, setPwd] = useState("");

  const [alertText, setAlertText] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");

  const REGISTER_URL = '/register';

  const clearInputs = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setPwd("");
  }

  const setAlert = (txt, severity) => {
    setAlertText(txt);
    setAlertSeverity(severity);
  }
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        REGISTER_URL,
        JSON.stringify({firstName, lastName, email, pwd}),
        {
          headers: {'Content-Type': 'application/json'},
          withCredentials: true
        }
      );
      console.log(response);
      setAlert("Success! Please reach out to your supervisor to have your account approved.", "success");
      clearInputs();
    } catch(err) {
      if (!err?.response) {
        console.log('No server response');
      } else {
        console.log(err.response);
        if (err.response.data == "Conflict") {
          setAlert("That email is already associated with an account.", "error");
        }
      }
    }
  }

  return (
    <div className="SignUp">
      <Container component="main" maxWidth="xs">
        <Box style={centerContent}>
              <img src={logo} alt="logo" style={{padding: "20px 25px"}} />
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      required
                      fullWidth
                      value={firstName}
                      id="firstName"
                      label="First Name"
                      name="firstName"
                      onChange={(e) => setFirstName(e.target.value)}
                      />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      required
                      fullWidth
                      value={lastName}
                      id="lastName"
                      label="Last Name"
                      name="lastName"
                      onChange={(e) => setLastName(e.target.value)}
                      />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      value={email}
                      id="email"
                      label="Email"
                      name="email"
                      onChange={(e) => setEmail(e.target.value)}
                      />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      name="password"
                      label="Password"
                      type="password"
                      value={pwd}
                      id="password"
                      autoComplete="new-password"
                      onChange={(e) => setPwd(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      fullWidth
                      variant="contained"
                      size="large"
                      type="submit"
                    >
                      Sign Up
                    </Button>
                  </Grid>
                </Grid>
              </form>
            { alertText != "" ?
              <Grid container spacing={2} sx={{marginTop:1}}>
                <Grid item xs={12}>
                      <Alert severity={alertSeverity}>{alertText}</Alert>
                </Grid>
              </Grid>
              : ""
            }
            <Grid container spacing={2} sx={{marginTop:1}}>
              <Grid item xs={12}>
                  <Link to="/login" style={{textDecoration: 'none'}}><Button variant="text">Already have an account?</Button></Link>
              </Grid>
            </Grid>
        </Box>
      </Container>
    </div>
  );
}

const centerContent = {
  height: "100vh",
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
  flexDirection: "column"
}

export default SignUp;
