import axios from 'axios';
const BASE_URL = 'https://api.looking-glass.sendwell.com';
const DEV_URL = 'http://localhost:3500';

export default axios.create({
    baseURL: BASE_URL
});

export const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    headers: { 'Content-Type': 'applicaton/json' },
    withCredentials: true
});