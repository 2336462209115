import * as React from 'react';
import {useEffect} from 'react';
import { Button, TableContainer, Table, TableBody, Container, Paper, Box, Typography, Stack, TextField, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import dayjs from 'dayjs';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import CondensedTable from '../components/CondensedTable';

function VariableColumns() {
    const axiosPrivate = useAxiosPrivate();
    const [keyValues, setKeyValues] = React.useState([]);
    const [displayedKeyValues, setDisplayedKeyValues] = React.useState([]);
    const [search, setSearch] = React.useState("");

    useEffect(() => {
        getDatabaseVars();
      }, []);

    const VARS_URL = '/variables/';
    const getDatabaseVars = async () => {
        try {
            const response = await axiosPrivate.get(VARS_URL);
            console.log(response);
            setKeyValues(response.data);
            setDisplayedKeyValues(response.data);
        } catch (err) {
            console.error(err);
        }
    }

    const getYesterdayVars = async () => {
        try {
            const response = await axiosPrivate.get(VARS_URL + dayjs().subtract(1, 'day').format("YYYY-MM-DD HH:mm:ss") + '/' + dayjs().format("YYYY-MM-DD HH:mm:ss"));
            getDatabaseVars();
            console.log(response);
        } catch (err) {
            console.error(err);
        }
    }
    const getLastWeekVars = async () => {
        try {
            const response = await axiosPrivate.get(VARS_URL + dayjs().subtract(7, 'day').format("YYYY-MM-DD HH:mm:ss") + '/' + dayjs().format("YYYY-MM-DD HH:mm:ss"));
            getDatabaseVars();
            console.log(response);
        } catch (err) {
            console.error(err);
        }
    }

    function compare( a, b ) {
        if ( a.key < b.key ){
          return -1;
        }
        if ( a.key > b.key ){
          return 1;
        }
        return 0;
    }

    const arrayByLabel = (data) => {
        let labelsDetected = [];
        for (const datum in data) {
            if (!labelsDetected.includes(data[datum].label)) {
                labelsDetected.push(data[datum].label);
            }
        }
        let arrays = [];
        for (const label in labelsDetected) {
            let keyValues = [];
            for (const datum in data) {
                if (labelsDetected[label] == data[datum].label) {
                    keyValues.push(data[datum]);
                }
            }
            arrays.push({
                displayName: labelsDetected[label],
                keyValues: keyValues
            });
        }
        for (const arr in arrays) {
            arrays[arr].keyValues.sort(compare);
        }
        return arrays;
    }

    const editKeyValue = async (keyValue) => {
        const response = await axiosPrivate.put(
            VARS_URL,
            JSON.stringify(keyValue),
            {
              headers: {'Content-Type': 'application/json'},
              withCredentials: true
            }
        );
        console.log(response);
        let index = keyValues.findIndex( (el) => el.key == keyValue.key);
        let newKeyValues = [...keyValues];
        newKeyValues[index] = keyValue;
        setKeyValues(newKeyValues);
        let displayedIndex = displayedKeyValues.findIndex( (el) => el.key == keyValue.key);
        let newDisplayedKeyValues = [...displayedKeyValues];
        newDisplayedKeyValues[displayedIndex] = keyValue;
        setDisplayedKeyValues(newDisplayedKeyValues);
    }

    const deleteKeyValue = async (keyValue) => {
        const response = await axiosPrivate.delete(
            VARS_URL,
            {
              data: JSON.stringify(keyValue),
              headers: {'Content-Type': 'application/json'},
              withCredentials: true
            }
        );
        console.log(response);
        let index = keyValues.findIndex( (el) => el.key == keyValue.key);
        let newKeyValues = [...keyValues];
        newKeyValues.splice(index, 1);
        setKeyValues(newKeyValues);
        let displayedIndex = displayedKeyValues.findIndex( (el) => el.key == keyValue.key);
        let newDisplayedKeyValues = [...displayedKeyValues];
        newDisplayedKeyValues.splice(displayedIndex, 1);
        setDisplayedKeyValues(newDisplayedKeyValues);
    }

    const addKeyValue = async (label, key) => {
        if (key.length == 0) {
            console.log("Key is required to create a new keyvalue.");
            return 0;
        }
        let keyValue = {
            label: label,
            key: key,
            value: parseFloat(0)
        }
        const response = await axiosPrivate.post(
            VARS_URL,
            JSON.stringify(keyValue),
            {
              headers: {'Content-Type': 'application/json'},
              withCredentials: true
            }
        );
        console.log(response);
        setKeyValues([...keyValues, keyValue]);
        setDisplayedKeyValues([...displayedKeyValues, keyValue]);
    }

    const handleSearch = () => {
        let filteredKeyValues = [];
        for (const keyValue in keyValues) {
            if (keyValues[keyValue].key.includes(search)) {
                filteredKeyValues.push(keyValues[keyValue]);
            }
        }
        setDisplayedKeyValues(filteredKeyValues);
    }

    return (
        <div className="VariableColumns">
            <Container component="main" maxWidth="md">
                <Box style={centerContent}>
                    <Typography component="h1" variant="h4" style={{marginBottom: "15px", fontWeight: "bold"}}>
                        Revenue By Variable
                    </Typography>
                    <Stack sx={{marginBottom:"15px"}} direction="row" alignItems="center" spacing={1}>
                        <Typography component="p" variant="p3">Import variables values from:</Typography>
                        <Button onClick={getYesterdayVars} variant="outlined" size="small">Yesterday</Button>
                        <Button onClick={getLastWeekVars} variant="outlined" size="small">Last Week</Button>
                    </Stack>
                    <Stack sx={{marginBottom:"25px"}} direction="row" alignItems="center" spacing={1}>
                        <TextField
                            variant="outlined"
                            placeholder="Search..."
                            size="small"
                            value={search}
                            onChange={(event) => {
                                setSearch(event.target.value);
                            }}
                            style={{width: "100%"}}
                        />
                        <IconButton type="submit" aria-label="search" onClick={handleSearch}>
                            <SearchIcon style={{ fill: "#1976d2" }} />
                        </IconButton>
                    </Stack>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableBody>
                            {arrayByLabel(displayedKeyValues).map((row,index) => (
                                <CondensedTable key={index} row={row} editFunc={editKeyValue} deleteFunc={deleteKeyValue} addFunc={addKeyValue} />
                            ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Container>
        </div>
    );
}


const centerContent = {
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    marginTop: "150px",
    paddingBottom: "30px"
  }

export default VariableColumns;
