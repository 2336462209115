import { Grid, TextField, Button, Container, Alert } from '@mui/material';
import { Box } from '@mui/system';
import * as React from 'react';
import {useState} from 'react';
import axios from '../api/axios';
import { Link, useNavigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';

// Assets
const logo = require('../assets/logo.png');

function Login() {

  const [email, setEmail] = useState("");
  const [pwd, setPwd] = useState("");

  const [alertText, setAlertText] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");

  const setAlert = (txt, severity) => {
    setAlertText(txt);
    setAlertSeverity(severity);
  }

  const {setAuth} = useAuth();
  const navigate = useNavigate();
  const LOGIN_URL = '/auth';
  const unverified_code = 197;

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        LOGIN_URL,
        JSON.stringify({email, pwd}),
        {
          headers: {'Content-Type': 'application/json'},
          withCredentials: true
        }
      );
      const accessToken = response?.data?.accessToken;
      const roles = response?.data?.roles;
      if (!roles.includes(unverified_code)) {
        setAuth({email, pwd, roles, accessToken});
        navigate('/');
      } else {
        setAlert("Your account still needs to be approved. Please reach out to your supervisor.", "warning");
      }

    } catch(err) {
      if (!err?.response) {
        console.log('No server response');
      } else {
        console.log(err.response);
        setAlert('Incorrect email or password.', 'error');
      }
    }
  }

  return (
    <div className="Login">
      <Container component="main" maxWidth="xs">
        <Box style={loginStyle}>
          <img src={logo} alt="logo" style={{padding: "20px 25px"}} />
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                  />
              </Grid>
              <Grid item xs={12}>
                <TextField
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="new-password"
                    onChange={(e) => setPwd(e.target.value)}
                    value={pwd}
                  />
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  size="large"
                >
                  Login
                </Button>
              </Grid>
            </Grid>
          </form>
          { alertText != "" ?
              <Grid container spacing={2} sx={{marginTop:1}}>
                <Grid item xs={12}>
                      <Alert severity={alertSeverity}>{alertText}</Alert>
                </Grid>
              </Grid>
              : ""
            }
            <Grid container spacing={2} sx={{marginTop:1}}>
              <Grid item xs={12}>
                  <Link to="/signup" style={{textDecoration: 'none'}}><Button variant='text'>Don't have an account?</Button></Link>
              </Grid>
            </Grid>
        </Box>
      </Container>
    </div>
  );
}

const loginStyle = {
  height: "100vh",
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
  flexDirection: "column"
}

export default Login;
