import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";

const RequireAuth = ( {allowedRoles} ) => {
    const {auth} = useAuth();
    const location = useLocation();

    return (
        // Chained ternary statement to see if the user is !logged in -> send to login, and !authorized -> send to unauthorized.
        auth?.roles?.find(role => allowedRoles?.includes(role))
            ? <Outlet />
            : auth?.email
                ? <Navigate to="/unauthorized" state={{from:location}} replace />
                : <Navigate to="/login" state={{from:location}} replace />
    );
}

export default RequireAuth;