import * as React from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Table, TableBody, TableCell, TableRow, Collapse, IconButton, Slider, Typography, TextField, Stack } from '@mui/material';

function CondensedRow(props) {
    const {keyValue, editFunc, deleteFunc } = props;
    const handleUpdate = (e, val) => {
        editFunc({label: keyValue.label, key: keyValue.key, value: val});
    }

    return (
        <TableRow>
            <TableCell>
                <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography component="p" variant="p">{keyValue.key}</Typography>
                    <IconButton onClick={() => deleteFunc(keyValue)} aria-label="delete"><DeleteForeverIcon /></IconButton>
                </Stack>
            </TableCell>
            <TableCell>
                <Slider value={parseFloat(keyValue.value)} onChangeCommitted={handleUpdate} aria-label="Default" valueLabelDisplay="auto" step={0.1} min={-0.9} max={0.9} />
            </TableCell>
        </TableRow>
    )
}

function CondensedTable(props) {
    const {row, editFunc, deleteFunc, addFunc } = props;
    const [open, setOpen] = React.useState(false);
    const [addKey, setAddKey] = React.useState("");

    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell align="left">
                    {row.displayName}
                </TableCell>
                <TableCell align="right">
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={()=>setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: 0, paddingRight: 0 }} colSpan={12}>
                <Collapse in={open} timeout="auto" unmountOnExit style={{backgroundColor: "#F8F8FF"}}>
                    <Table sx={{width:"99%"}}>
                        <TableBody>
                            {row.keyValues.map((keyValue,index) => (
                                <CondensedRow key={'kv-' + index} deleteFunc={deleteFunc} editFunc={editFunc} keyValue={keyValue} />
                            ))}
                            <TableRow>
                                <TableCell>
                                    <TextField
                                        label="Add a new value."
                                        variant="outlined"
                                        value={addKey}
                                        onChange={(e) => setAddKey(e.target.value)}
                                    />
                                </TableCell>
                                <TableCell align="right">
                                    <IconButton aria-label="add" onClick={() => addFunc(row.displayName, addKey)}>
                                        <AddCircleIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export default CondensedTable;