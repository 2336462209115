import * as React from 'react';
import { useEffect, useState } from 'react';
import { Typography, Container, List, ListItem, ListItemAvatar, ListItemText, IconButton, Avatar } from '@mui/material';
import { Box } from '@mui/system';
import { Link } from 'react-router-dom';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import EditIcon from '@mui/icons-material/Edit';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

function Users() {
  const [users, setUsers] = useState([]);
  const axiosPrivate = useAxiosPrivate();

  const USERS_URL = '/users';
  useEffect(() => {
    const getUsers = async () => {
      try {
        const response = await axiosPrivate.get(USERS_URL);
        setUsers(response.data);
      } catch (err) {
        console.error(err);
      }
    }
    getUsers();
  }, [])

  const userList = users.map((user, index) => 
    <ListItem
      key={index}
      secondaryAction={
        <IconButton edge="end" aria-label="edit">
          <Link to={"/user/" + user.email}>
            <EditIcon />
          </Link>
        </IconButton>
      }
    >
      <ListItemAvatar>
        <Avatar>
          <AccountCircleIcon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={user.firstname + " " + user.lastname}
        secondary={user.email}
      />
    </ListItem>,
  );

  return (
    <div className="Users">
      <Container component="main" maxWidth="xs">
        <Box style={centerContent}>
            <Typography component="h1" variant="h4" style={{marginBottom: 30, fontWeight: "bold"}}>
                User Profiles
            </Typography>
            <List sx={{width:"100%"}}>
              {userList}
            </List>
        </Box>
      </Container>
    </div>
  );
}

const centerContent = {
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  marginTop: "150px"
}

export default Users;
