import * as React from 'react';
import { useState, useEffect } from 'react';
import { Box, Stack } from '@mui/system';
import {TextField, FormControl, InputLabel, Select, MenuItem, Button, Alert, Typography, Container, Grid} from '@mui/material';
import { useParams, Link, useNavigate } from 'react-router-dom';
import useAxiosPrivate from '../hooks/useAxiosPrivate';

function User() {

  const{ userEmail } = useParams();
  const [user, setUser] = useState({});
  const [enableEdits, setEnableEdits] = useState(false);

  const [firstName, setFirstName] = useState("loading");
  const [lastName, setLastName] = useState("loading");
  const [email, setEmail] = useState("loading");
  const [role, setRole] = useState("Unverified");
  const [pwd, setPwd] = useState("");

  const axiosPrivate = useAxiosPrivate();

  const [alertText, setAlertText] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");

  const navigate = useNavigate();

  const setAlert = (txt, severity) => {
    setAlertText(txt);
    setAlertSeverity(severity);
  }

  const getHighestRole = (user) => {
    if (user.roles?.includes(1387)) {
      return "Admin";
    } else if (user.roles?.includes(3943)) {
      return "User";
    }
    return "Unverified";
  }

  const USERS_URL = '/users/' + userEmail;
  useEffect(() => {
    const getUsers = async () => {
      try {
        const response = await axiosPrivate.get(USERS_URL);
        setUser(response.data);
        setFirstName(response.data.firstname);
        setLastName(response.data.lastname);
        setEmail(response.data.email);
        setRole(getHighestRole(response.data));
      } catch (err) {
        console.error(err);
      }
    }
    getUsers();
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let newRoles = [];
      if (role == "Admin") {
        newRoles.push(1387);
      } else if (role == "User") {
        newRoles.push(3943);
      } else {
        newRoles.push(197);
      }
      let modifiedUser = {
        firstName: firstName,
        lastName: lastName,
        email: user.email,
        roles: newRoles
      }
      if (pwd !== "") {
        modifiedUser.pwd = pwd;
      }
      console.log(modifiedUser);
      const response = await axiosPrivate.put(
        USERS_URL,
        JSON.stringify(modifiedUser),
        {
          headers: {'Content-Type': 'application/json'},
          withCredentials: true
        }
      );
      console.log(response);
      setAlert("Success! User updated.", "success");
      setEnableEdits(false);
    } catch(err) {
      if (!err?.response) {
        console.log('No server response');
      } else {
        console.log(err.response);
      }
    }
  }

  const handleDelete = async() => {
    const response = await axiosPrivate.delete(
      '/users/',
      {
        data: { email: email },
        headers: {'Content-Type': 'application/json'},
        withCredentials: true
      }
    );
    console.log(response);
    navigate('/users/');
  }

  return (
    <div className="User">
      <Container component="main" maxWidth="xs">
        <Box style={centerContent}>
            <Typography component="h1" variant="h4" style={{fontWeight: "bold"}}>
                User Profile
            </Typography>
            {enableEdits && role == 'Unverified' && <Button onClick={handleDelete}>Permanently delete?</Button>}
            <form onSubmit={handleSubmit} style={{marginTop: 30}}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <TextField
                        required
                        disabled={!enableEdits}
                        fullWidth
                        value={firstName}
                        id="firstName"
                        name="firstName"
                        label="First Name"
                        onChange={(e) => setFirstName(e.target.value)}
                        />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        required
                        disabled={!enableEdits}
                        fullWidth
                        value={lastName}
                        id="lastName"
                        name="lastName"
                        label="Last Name"
                        onChange={(e) => setLastName(e.target.value)}
                        />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        disabled
                        fullWidth
                        value={email}
                        id="email"
                        name="email"
                        autoComplete="email"
                        label="email"
                        />
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth required>
                        <InputLabel id="demo-simple-select-label">Role</InputLabel>
                        <Select
                            disabled={!enableEdits}
                            labelId="select-role-label"
                            id="select-role"
                            value={role}
                            onChange={(e) => setRole(e.target.value)}
                            label="Role"
                        >
                            <MenuItem value={"Unverified"}>Unverified</MenuItem>
                            <MenuItem value={"User"}>User</MenuItem>
                            <MenuItem value={"Admin"}>Admin</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                {
                    enableEdits ?
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            disabled={!enableEdits}
                            value={pwd}
                            id="pwd"
                            name="pwd"
                            autoComplete="password"
                            label="New Password"
                            onChange={(e) => setPwd(e.target.value)}
                            />
                    </Grid>
                    : ""
                }
                <Grid item xs={12}>
                    <Button
                      fullWidth
                      variant="contained"
                      size="large"
                      type="submit"
                      disabled={!enableEdits}
                    >
                      Update
                    </Button>
                  </Grid>
              </Grid>
            </form>
            { alertText != "" ?
              <Grid container spacing={2} sx={{marginTop:1}}>
                <Grid item xs={12}>
                      <Alert severity={alertSeverity}>{alertText}</Alert>
                </Grid>
              </Grid>
              : ""
            }
            <Stack direction="row" justifyContent="space-between" sx={{width: "100%", margin: 2}}>
              <Link to="/users" style={{textDecoration: 'none'}}>
                <Button variant="text">Back to users list.</Button>
              </Link>
              <Button
                variant="text"
                onClick={() => setEnableEdits(!enableEdits)}>
                    {enableEdits ? "Abandon edits." : "Edit profile."}
              </Button>
            </Stack>
        </Box>
      </Container>
    </div>
  );
}

const centerContent = {
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  marginTop: "150px"
}

export default User;
