import * as React from 'react';
import { useState } from 'react';
import { Button, Checkbox, FormControlLabel } from '@mui/material';
import {Stack} from '@mui/system';
import AggregationTable from '../components/AggregationTable';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import LoadingIcon from '../components/LoadingIcon';

// Date Time Picker
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import TextField from '@mui/material/TextField';

// Parse Float so that sorting still works, since toFixed returns a string
const deriveRevPer1000 = (row) => {
  let revenuePer1000 = 0;
  // Avoid divide by zero error
  if (row.emailSent !== 0) {
    revenuePer1000 = row.totalRevenue/(row.emailSent/1000);
  }
  return revenuePer1000;
}

// Apply sensible rounding for money
const deriveRev = (row) => {
  return parseFloat(row.totalRevenue);
}

const deriveDate = (row) => {
  return dayjs(row.sendDate).format('YYYY-MM-DD HH:mm:ss');
}

function Reporting() {

  const axiosPrivate = useAxiosPrivate();

  const [loading, setLoading] = useState(false);

  const [jobs, setJobs] = useState([]);
  const [keyValues, setKeyValues] = useState([]);

  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);
  const [overwriteChecked, setOverwriteChecked] = React.useState(false);

  const [startTimestamp, setStartTimestamp] = React.useState(dayjs().subtract(1, 'day').format("YYYY-MM-DD HH:mm:ss"));
  const [endTimestamp, setEndTimestamp] = React.useState(dayjs().format("YYYY-MM-DD HH:mm:ss"));

  const handleOverwriteChange = (event) => {
    setOverwriteChecked(event.target.checked);
  };

  const JOBS_URL = '/jobs/';
  const getJobs = async () => {
    setLoading(true);
    try {
      let response;
      if (overwriteChecked) {
        // Do a post request if we want to update the db's data
        response = await axiosPrivate.post(
          JOBS_URL,
          JSON.stringify({startTimestamp, endTimestamp}),
          {
            headers: {'Content-Type': 'application/json'},
            withCredentials: true
          }
        );
      } else {
        response = await axiosPrivate.get(JOBS_URL + startTimestamp + '/' + endTimestamp);
      }
      console.log(response);
      setJobs(response.data);
    } catch (err) {
        console.error(err);
    }
    setLoading(false);
  }

  const getKeyValues = async () => {
    try {
      const response = await axiosPrivate.get('/variables/');
      setKeyValues(response.data);
    } catch (err) {
      console.error(err);
    }
  }

  const handleStartTimestamp = (newValue) => {
    newValue = newValue.format("YYYY-MM-DD HH:mm:ss");
    setStartTimestamp(newValue);
  };

  const handleEndTimestamp = (newValue) => {
    newValue = newValue.format("YYYY-MM-DD HH:mm:ss");
    setEndTimestamp(newValue);
  };

  const submitTimestamps = () => {
    getKeyValues();
    getJobs();
  }

  const deriveRevPerVar = (row) => {
    let rpv = deriveRevPer1000(row);
    for (const keyValue in keyValues) {
      let label = keyValues[keyValue].label;
      let key = keyValues[keyValue].key;
      let val = keyValues[keyValue].value;
      // The value of this column has an applicable RPV variable
      if (row[label] === key) {
        rpv += (rpv * ( -1 * val));
      }
    }
    return rpv;
  }

  const deriveSynced = (row) => {
    return row.synced ? "✅" : "❌";
  }

  const dataStructure = [
    {
      attr: "displaySync",
      displayName: "Synced?",
      deriveValue: deriveSynced
    },
    {
      attr: "subid",
      displayName: "Sub ID"
    },
    {
      attr: "sendDate",
      displayName: "Date",
      deriveValue: deriveDate
    },
    {
      attr: "deploymentId",
      displayName: "Deployment ID"
    },
    {
      attr: "name",
      displayName: "Offer Name"
    },
    {
      attr: "emailSent",
      displayName: "Total Email Sent"
    },
    {
      attr: "mailingSpecialist",
      displayName: "Campaign Mailing Specialist"
    },
    {
      attr: "emailServiceProvider",
      displayName: "Email Service Provider"
    },
    {
      attr: "list",
      displayName: "List"
    },
    {
      attr: "totalRevenue",
      displayName: "Total Revenue",
      format: "currency",
      deriveValue: deriveRev
    },
    {
      attr: "revenuePer1000",
      displayName: "Revenue Per 1000",
      format: "currency",
      deriveValue: deriveRevPer1000
    },
    {
      attr: "revenuePerVariable",
      displayName: "RPV",
      format: "currency",
      deriveValue: deriveRevPerVar
    }
  ];

  return (
    <div className="Reporting" style={{padding: "10px 30px"}}>
        <Stack direction="row" spacing={2} sx={{marginBottom: 2}}>
          <Button onClick={() => setShowAdvancedOptions(!showAdvancedOptions)} variant="text">{showAdvancedOptions ? "Hide" : "Display"} advanced options.</Button>
        </Stack>
        {showAdvancedOptions &&
        <Stack direction="row" spacing={2} sx={{marginBottom: 2}}>
          <FormControlLabel labelPlacement='start' control={<Checkbox checked={overwriteChecked} onChange={handleOverwriteChange} />} label="Overwrite database records." />
        </Stack>
        }
        <Stack direction="row" spacing={2} alignItems="center" sx={{marginBottom: 2}}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                  label="Start"
                  value={startTimestamp}
                  onChange={handleStartTimestamp}
                  renderInput={(params) => <TextField {...params} />}
              />
              <DateTimePicker
                  label="End"
                  value={endTimestamp}
                  onChange={handleEndTimestamp}
                  renderInput={(params) => <TextField {...params} />}
              />
          </LocalizationProvider>
          <Button onClick={submitTimestamps} variant="outlined" size="large" style={{padding: "13px 20px"}}>Submit</Button>
          {loading && <LoadingIcon />}
        </Stack>
        <AggregationTable
          aggregateValues={["emailSent", "totalRevenue"]}
          aggregateMinimum={["sendDate"]}
          aggregateMode={["synced"]}
          aggregateNone={["subid", "emailServiceProvider", "mailingSpecialist"]}
          aggregationProperties={["list", "name"]}
          initialSort="totalRevenue"
          dataStructure={dataStructure}
          rows={jobs}
        />
    </div>
  );
}

export default Reporting;
