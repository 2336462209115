import { Stack } from "@mui/system";
import { Outlet } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from '../api/axios';
import { useNavigate } from "react-router-dom";

// Assets
const logo = require('../assets/logo-white.png');

const Layout = () => {
    const navigate = useNavigate();
    const LOGOUT_URL = '/logout';
    const handleLogout = async () => {
        try {
            const response = await axios.get(LOGOUT_URL);
            console.log(response);
            navigate('/login');
        } catch (err) {
            console.error(err);
        }
    }

    return (
        <main>
            <Stack direction="row" justifyContent="space-between" alignItems="center" style={navStyles} spacing={2}>
                <Stack direction="row" spacing={2}>
                    <img src={logo} alt="logo" style={{padding: "5px", width: "200px"}} />
                </Stack>
                <Stack direction="row" spacing={2}>
                    <Link style={navLink} to="/">Reporting</Link>
                    <Link style={navLink} to="users">Users</Link>
                    <Link style={navLink} to="variables">Variables</Link>
                    <Link style={navLink} onClick={handleLogout}>Logout</Link>
                </Stack>
            </Stack>
            <Outlet />
        </main>
    )
}

const navStyles = {
    backgroundColor: "#1976d2",
    padding: 20,
    marginBottom: 40
}

const navLink = {
    color: "#fff",
    fontWeight: "bold"
}

export default Layout